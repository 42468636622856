<template>
  <div>
    <div id="bottomLeftChart" class="bottom1Chart"></div>
  </div>
</template>

<script>
import { averArea, maxArea } from "../../../axios/api/LargeScreen";
export default {
  data() {
    return {
      chart: null,
      newData: {
        category: [],
        lineData: [],
        barData: [],
        rateData: [],
      },
    };
  },

  mounted() {
    this.setData();
  },
  methods: {
    async setData() {
      await averArea()
        .then((res) => {
          res.data.forEach((e) => {
            this.newData.category.push(e.name);
            this.newData.barData.push(e.value);
          });
        })
        .catch((err) => {});

      await maxArea()
        .then((res) => {
          res.data.forEach((e) => {
            this.newData.lineData.push(e.value);
          });
        })
        .catch((err) => {});

      this.calculateRateData();
      this.draw();
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("bottomLeftChart")
      );

      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC",
            },
          },
        },
        legend: {
          data: ["最高震级", "平均震级", "人数"],
          textStyle: {
            color: "#B4B4B4",
          },
          top: "0%",
        },
        grid: {
          x: "8%",
          width: "88%",
          y: "4%",
        },
        xAxis: {
          data: this.newData.category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
        ],
        series: [
          // {
          //   name: "次数",
          //   type: "line",
          //   smooth: true,
          //   showAllSymbol: true,
          //   symbol: "emptyCircle",
          //   symbolSize: 8,
          //   yAxisIndex: 1,
          //   itemStyle: {
          //     normal: {
          //       color: "#F02FC2",
          //     },
          //   },
          //   data: this.newData.rateData,
          // },
          {
            name: "平均震级",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#956FD4" },
                  { offset: 1, color: "#3EACE5" },
                ]),
              },
            },
            data: this.newData.barData,
          },
          {
            name: "最高震级",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(156,107,211,0.8)" },
                  { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                  { offset: 1, color: "rgba(156,107,211,0.2)" },
                ]),
              },
            },
            z: -12,
            data: this.newData.lineData,
          },
        ],
      });
    },
    calculateRateData() {
      for (let i = 0; i < this.newData.barData.length; i++) {
        let rate = this.newData.barData[i] / this.newData.lineData[i];
        this.newData.rateData.push(rate);
      }
    },
  },
  unmounted() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
.bottom1Chart {
  width: 100%;
  height: 380px;
}
</style>
