<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b fs-xl">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span class="fs-xl text mx-2 mb-1 pl-3"
          >四川伤亡人数排行（2013-2023）</span
        >
        <dv-scroll-ranking-board class="dv-scr-rank-board" :config="ranking1" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>2023年地震发生率（2020-2024）</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>2023年人口死亡率（2020-2024）</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center";
import {
  deathtop10,
  infoLeft,
  infoMid,
  ratio,
} from "../../axios/api/LargeScreen";
export default {
  data() {
    return {
      rank: [],
      rank1: [],
      titleItem: [],
      water: {
        data: [""],
        shape: "roundRect",
        formatter: "四川震情信息",
        waveNum: 3,
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 4,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5",
              },
            },
          },
        },
        {
          id: "centerRate2",
          tips: 3,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad",
              },
            },
          },
        },
      ],
    };
  },
  computed: {
    ranking1: {
      get() {
        const result = {
          data: [],
          carousel: "single",
          unit: "人",
        };
        this.rank.forEach((e) => {
          e.name=e.name+"("+e.year+"年)";
          e.value = Math.round(e.value);
        });
        result.data = this.rank;
        return result;
      },
    },
  },
  mounted() {
    this.Datalist();
  },
  methods: {
    async Datalist() {
      try {
        const res = await deathtop10();
        this.rank = res.data;
      } catch (err) {
        console.error("Error fetching data:", err);
      }
      await infoLeft()
        .then((res) => {
          this.rank1 = res.data;
        })
        .catch((err) => {});
      await infoMid()
        .then((res) => {
          // 将获取的数据应用到 titleItem 数组中
          this.titleItem = res.data.map((item) => ({
            title: item.id,
            number: {
              number: [item.value],
              toFixed: 0,
              textAlign: "left",
              content: "{nt}",
              style: {
                fontSize: 26,
              },
            },
          }));
        })
        .catch((err) => {});
      await ratio()
        .then((res) => {
          // 遍历后端数据，将每个对象的 name 字段赋值给 rate 数组中对应的对象的 id 字段，
          // 将 value 字段赋值给 tips 字段
          res.data.forEach((item, index) => {
            this.rate[index].id = item.name;
            this.rate[index].tips = Math.round(item.value * 100);
          });
        })
        .catch((err) => {});
    },
  },

  components: {
    CenterChart,
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .bg-color-black {
    background-color: rgba(19, 25, 47, 0.6);
  }
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding: 0 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;

      .dv-dig-flop {
        width: 150px;
        height: 30px;
        padding-left: 10px;
      }
      .colorBlue {
        color: #257dff;
        font-size: 12px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 205px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 180px;
        padding-top: 5px;
      }
      .text {
        color: #fff;
        font-size: 12px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 100px;
        span {
          margin-top: 5px;
          font-size: 12px;
          color: #fff;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        margin-top: 20px;
        .dv-wa-le-po {
          height: 100px;
        }
      }
    }
  }
}
</style>
