import {http } from "../httpConfig";
function magDivide() {
    return http.get('/xinjiang/magDivide')
}
function timeeveryyear(){
    return http.get('/xinjiang/timeeveryyear', { year: '10' } )
}
function magtop5(){
    return http.get('/xinjiang/magtop5', { num: 5 } )
}
function deathtop6(){
    return http.get('/xinjiang/deathtop5', { num: 5} )
}
function yearszoushi(){
    return http.get('/xinjiang/yearszoushi', { year: 6 } )
}
function yearsDepth(){
    return http.get('/xinjiang/yearsDepth', { year: 10 } )
}
function yearstimes(){
    return http.get('/xinjiang/yearstimes', { year: 10 } )
}
//全国
function magDivide1(){
    return http.get('/country/magDivide' )
}
function deathAll(){
    return http.get('/country/deathAll' )
}
function frequent(pa){
    return http.get('/country/frequent',{'year':pa})
}
function deathtop5(){
    return http.get('/country/deathtop5',{num:5} )
}
function magtop8(){
    return http.get('/country/magtop',{num:5} )
}
function magtop9(){
    return http.get('/country/magtop5',{num:5} )

}
function zhanbi(){
    return http.get('/country/zhanbi' )
}
function averDis(num1){
    return http.get('/country/averDis' ,{num:num1})
}
//sichuan
function infoLeft(){
    return http.get('/sichuan/infoLeft' )
}
function zhanbi1(){
    return http.get('/sichuan/zhanbi' )
}
function countArea(){
    return http.get('/sichuan/countArea',{num:10} )
}
function averArea(){
    return http.get('/sichuan/averArea',{num:15} )
}
function maxArea(){
    return http.get('/sichuan/maxArea',{num:15} )
}
function frequency(){
    return http.get('/sichuan/frequency')
}
function deathtop10(){
    return http.get('/sichuan/deathtop10',{num:10})
}
function magtop6(){
    return http.get('/sichuan/magtop5',{num:6})
}
function wuzi(params){
    return http.get('/sichuan/wuzi',{num:params})
}
function infoMid(){
    return http.get('/sichuan/infoMid')
}
function ratio(){
    return http.get('/sichuan/ratio')
}
export{
    magDivide,
    timeeveryyear,
    magtop5,
    deathtop6,
    yearszoushi,
    yearsDepth,
    yearstimes,
    magDivide1,
    deathAll,
    frequent,
    deathtop5,
    magtop8,
    zhanbi,
    averDis,
    infoLeft,
    zhanbi1,
    countArea,
    averArea,
    maxArea,
    frequency,
    deathtop10,
    magtop6,
    wuzi,
    infoMid,
    ratio,
    magtop9,

}