<template>
  <div id="centreLeft2">
    <div class="bg-color-black">
      <div class="pt-2 pl-2">
        <!-- <span class="fs-xl text mx-2">根据地震数目选取十个省份</span> -->
        <dv-decoration-3 class="dv-dec-3" />
      </div>
      <div class="chart-box jc-center">
        <span class="fs-xl text mx-2">2009-2024年来频繁发生地震的地区次数</span>
        <centreLeft2Chart />
      </div>
    </div>
  </div>
</template>

<script>
import centreLeft2Chart from "@/components/echart/centerLeft/centreLeft3Chart.vue";
export default {
  data() {
    return {};
  },
  components: {
    centreLeft2Chart,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#centreLeft2 {
  padding: 16px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  .bg-color-black {
    height: calc(100% - 30px);
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    padding-top: 30px;
    height: calc(100% - 30px);
    width: 100%;
  }
}
</style>
