<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex">
        <span class="fs-xl text mx-2">2017-2024年四川平均地震次数</span>
        <div class="decoration2">
          <dv-decoration-2 :reverse="true" />
        </div>
      </div>
      <div>
        <BottomRightChart />
      </div>
    </div>
  </div>
</template>

<script>
import BottomRightChart from "@/components/echart/bottom/bottomRightChart.vue";
export default {
  components: {
    BottomRightChart,
  },
};
</script>

<style lang="scss" class>
#bottomRight {
  padding: 14px 16px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  .bg-color-black {
    height: calc(100% - 35px);
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 16px;
    width: 150px;
    height: 150px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
