<template>
  <div>
    <div id="centreLeft2Chart" class="Left2Chart"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixin";
import { zhanbi1 } from "../../../axios/api/LargeScreen";
export default {
  data() {
    return {
      chart: null,
      magnitudeData: [], // 存储地震级别百分比数据
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    async draw() {

      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(
          document.getElementById("centreLeft2Chart")
      );
      // 发起异步请求获取数据
      await zhanbi1()
          .then((res) => {
            // 将后端数据转换成前端需要的格式
            this.magnitudeData = res.data.map(item => ({
              value: item.value,
              name: item.name // 使用 JSON 数据中的 "name" 字段作为地震级别
            }));
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
          });

        // 设置图表选项
        this.chart.setOption({
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea",
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          toolbox: {
            show: true,
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: 0,
            x: "center",
            data: this.magnitudeData.map((item) => item.name), // 使用 magnitudeData 的地震级别生成 legend 数据
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              name: "四川总数",
              type: "pie",
              radius: [10, 60],
              roseType: "area",
              center: ["50%", "40%"],
              data: this.magnitudeData, // 使用 magnitudeData 设置 series 数据
            },
          ],
        });
      }
  },
  unmounted() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
.Left2Chart {
  width: 100%;
  height: 220px;
}
</style>
