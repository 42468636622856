<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span class="fs-xl text mx-2">2000-2024年四川震级及物资</span>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <dv-capsule-chart class="dv-cap-chart" :config="config1" />
        <centerRight2Chart1 />
      </div>
    </div>
  </div>
</template>

<script>
import centerRight2Chart1 from "@/components/echart/centerRight";
import { magtop6 } from "../../axios/api/LargeScreen";
export default {
  data() {
    return {
      zhenji: [],
    };
  },
  computed: {
    config1: {
      get() {
        const result = {
          data: [],
        };
        result.data = this.zhenji;
        return result;
      },
    },
  },
  mounted() {
    this.DataList();
  },
  methods: {
    async DataList() {
      await magtop6()
        .then((res) => {
          this.zhenji = res.data.map(({ name, magnitude ,year}) => ({
            name: name+"("+year+")",
            value: magnitude,
          }));
          console.log(this.zhenji, 467);
        })
        .catch((err) => {});
    },
  },
  components: { centerRight2Chart1 },
};
</script>

<style lang="scss" scoped>
#centerRight2 {
  padding: 5px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  .bg-color-black {
    padding: 5px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 130px;
    }
  }
}
</style>
