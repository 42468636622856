<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <dv-decoration-3 class="dv-dec-3" />
      </div>
      <div class="chart-box">
        <span class="fs-xl text mx-2">2000-2024地震级别</span>
        <CenterLeft1Chart />
      </div>

<!--      <span class="fs-xl text mx-2">（取对数后）</span>-->
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" v-for="(item, index) in numberData" :key="index">
          <div class="d-flex">
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <div class="text" style="text-align: center">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centreLeft2Chart.vue";
import { infoLeft } from "../../axios/api/LargeScreen";
export default {
  data() {
    return {
      numberData: [],
    };
  },
  components: {
    CenterLeft1Chart,
  },
  mounted() {
    // this.changeTiming()
    this.DataList();
  },
  methods: {
    async DataList() {
      await infoLeft()
        .then((res) => {
          res.data.forEach((e) => {
            this.numberData.push({
              number: {
                number: [Math.round(e.value)],
                toFixed: 0,
                textAlign: "left",
                content: "{nt}",
                style: {
                  fontSize: 24,
                },
              },
              text: e.id,
            });
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
#centerLeft1 {
  padding: 16px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  .bg-color-black {
    height: calc(100% - 30px);
    border-radius: 10px;
  }
  .d-flex {
    display: flex;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    .item-box {
      & > div {
        padding-right: 5px;
        padding-bottom: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      .dv-digital-flop {
        // padding-left: 40px;
        text-align: center;
        margin: 0 auto;
        // background-color: pink;
        // width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 0px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>
