<template>
  <div>
    <div id="centreRightChart" class="RightChart"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixin";
import {wuzi} from "@/axios/api/LargeScreen";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    async draw() {
      let lineStyle = {
        normal: {
          width: 1,
          opacity: 0.5,
        },
      };
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("centreRightChart")
      );

      const responsesichuan = await wuzi(10092);
      const responseyunnan = await wuzi(5841);
      const responsexinjiang = await wuzi(10260);
      const ds = responsesichuan.data.map(item => parseFloat(item.value).toFixed(3));
      const dy = responseyunnan.data.map(item => parseFloat(item.value).toFixed(3));
      const dx = responsexinjiang.data.map(item => parseFloat(item.value).toFixed(3));
      this.chart.setOption({
        radar: {
          indicator: [
            { name: "急救包（千袋）", max: 300 },
            { name: "床（万个）", max: 1000 },
            { name: "通信工具（万个）", max: 200 },
            { name: "衣服（万件）", max: 4000 },
            { name: "食物（万吨）", max: 4000 },
            { name: "水（万升）", max: 500 },
          ],
          shape: "circle",
          splitNumber: 5,
          radius: ["0%", "65%"],
          name: {
            textStyle: {
              color: "rgb(238, 197, 102)",
            },
          },
          splitLine: {
            lineStyle: {
              color: [
                "rgba(238, 197, 102, 10)",
                "rgba(238, 197, 102, 20)",
                "rgba(238, 197, 102, 40)",
                "rgba(238, 197, 102, 60)",
                "rgba(238, 197, 102, 80)",
                "rgba(238, 197, 102, 1)",
              ].reverse(),
            },
          },
          splitArea: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(238, 197, 102, 0.5)",
            },
          },
        },
        series: [
          {
            name: "云南",
            type: "radar",
            lineStyle: lineStyle,
            data: [
              ds,
              dy,
              dx
            ],
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#F9713C",
              },
            },
            areaStyle: {
              normal: {
                opacity: 0.1,
              },
            },
          },
          {
            name: "四川",
            type: "radar",
            lineStyle: lineStyle,
            data: [
              ds,
              dy,
              dx
            ],
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#B3E4A1",
              },
            },
            areaStyle: {
              normal: {
                opacity: 0.05,
              },
            },
          },
          {
            name: "新疆",
            type: "radar",
            lineStyle: lineStyle,
            data: [
              ds,
              dy,
              dx
            ],
            symbol: "none",
            itemStyle: {
              normal: {
                color: "rgb(238, 197, 102)",
              },
            },
            areaStyle: {
              normal: {
                opacity: 0.05,
              },
            },
          }, //end
        ],
      });
    },
  },
  unmounted() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
.RightChart {
  width: 100%;
  height: 200px;
}
</style>
