<template>
  <div class="sichuanPage">
    <div class="navClass">
      <NavDemo title="四川震害与救援视界" style="height: 100%" />
    </div>
    <div class="moveClass">
      <div class="topClass">
        <dv-border-box-12 style="width: 15%">
          <centerLeft1 />
        </dv-border-box-12>
        <dv-border-box-12 style="width: 20%">
          <centerLeft2 />
        </dv-border-box-12>
        <center style="width: 32%" />
        <centerRight2 style="width: 18%" />
        <dv-border-box-13 style="width: 15%">
          <centerRight1 />
        </dv-border-box-13>
      </div>
      <div class="bottomClass a">
        <dv-border-box-13 class="demo3Class">
          <bottomLeft />
        </dv-border-box-13>
        <dv-border-box-12 class="demo3Class">
          <bottomRight />
        </dv-border-box-12>
      </div>
    </div>
  </div>
</template>

<script>
import centerLeft1 from "./centerLeft1";
import centerLeft2 from "./centerLeft2";
import centerRight1 from "./centerRight1";
import centerRight2 from "./centerRight2";
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import NavDemo from "@/components/FrmHeader.vue";

export default {
  data() {
    return {
      timing: null,
      loading: true,
    };
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight,
    NavDemo,
  },
  mounted() {
    this.cancelLoading();
  },
  beforeUnmount() {
    clearInterval(this.timing);
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.sichuanPage {
  height: 100%;
  background-image: url("../../../public/img/stars.jpg");
  background-size: cover;
  background-position: center center;

  .navClass {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100%;
    z-index: 5;
  }

  .moveClass {
    position: absolute;
    height: 90%;
    width: 100%;
    pointer-events: none;
    top: 10%;
    //overflow: auto;
    padding: 10px 20px;
    .demoClass {
      pointer-events: auto;
      height: 100%;
      width: 15%;
    }
    .demo3Class {
      height: 100%;
      width: 50%;
    }

    .topClass {
      height: 50%;
      padding-bottom: 15px;
      overflow: hidden;
      display: flex;
    }

    .bottomClass {
      height: 50%;
      width: 100%;
      display: flex;
    }
  }
}
</style>
