<template>
  <div>
    <div id="centreLeft3Chart" class="Left3Chart"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixin";
import { countArea } from "../../../axios/api/LargeScreen";
export default {
  data() {
    return {
      chart: null,
      areaData: [], // 存储地区和数值数据
      colors: [
        "#f54d4d",
        "#f87544",
        "#ffae00",
        "#dcff00",
        "#25d053",
        "#01fff5",
        "#007cff",
        "#4245ff",
        "#c32eff",
        "#ff62e8",
      ], // 自定义颜色数组
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.draw();
  },
  methods: {
    async draw() {
      // 初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("centreLeft3Chart")
      );
      try {
        // 发起异步请求获取数据
        const res = await countArea();

        // 处理后端返回的数据
        this.areaData = res.data.map((item) => ({
          name: item.name,
          value: item.value,
        }));

        // 设置图表选项
        let option = {
          angleAxis: {
            interval: 1,
            type: "category",
            data: this.areaData.map((item) => item.name), // 使用地区数据设置 angleAxis.data
            z: 10,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#00c7ff",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              interval: 0,
              show: true,
              color: "#00c7ff",
              margin: 8,
              fontSize: 12,
            },
          },
          radiusAxis: {
            min: 0,
            max: 300,
            interval:50,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#00c7ff",
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              formatter: "{value} %",
              show: false,
              padding: [0, 0, 20, 0],
              color: "#00c7ff",
              fontSize: 16,
            },
            splitLine: {
              lineStyle: {
                color: "#00c7ff",
                width: 1,
                type: "solid",
              },
            },
          },
          legend: {
            show: true,
            orient: "vertical",
            top: "center",
            bottom: "0%",
            itemWidth: 16,
            itemHeight: 8,
            itemGap: 16,
            textStyle: {
              color: "#A3E2F4",
              fontSize: 12,
              fontWeight: 0,
            },
            data: ["A"],
          },
          polar: {},
          series: [
            {
              name: "A",
              type: "bar",
              //radius: ["20%", "100%"],
              data: this.areaData.map((item, index) => ({
                value: item.value,
                itemStyle: {
                  normal: {
                    color: this.colors[index % this.colors.length], // 使用自定义颜色数组
                  },
                },
              })), // 使用地区数值数据设置 series.data
              coordinateSystem: "polar",
            },
          ],
        };
        this.chart.setOption(option);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  unmounted() {
    window.onresize = null;
  },
};
</script>

<style lang="scss" scoped>
.Left3Chart {
  width: 100%;
  height: 300px;
}
</style>
