<template>
    <el-row class="topNavClass">
      <el-col :span="6" class="flexClass">
        <!-- {{ sysTime }} -->
        <dv-decoration-10 :dur="5" class="animationClass" />
      </el-col>
      <el-col :span="12" style="position: relative">
        <h1 class="title">{{title}}</h1>
        <!-- <div class="title">
          {{ $settings.title }}
        </div> -->
        <dv-decoration-8 class="headLineClass" :color="['#00c2ff', '']" />
        <dv-decoration-8 class="headLineClass" :color="['#00c2ff', '']" style="transform: rotateY(180deg); right: 0" />
        <dv-decoration-6 class="bottomClass" :color="['#00c2ff', '#abd1ff']" />
      </el-col>
      <el-col :span="6" class="flexClass">
        <!-- {{ currentTime }} -->
        <dv-decoration-10 :dur="5" class="animationClass" style="transform: rotateY(180deg)" />
      </el-col>
    </el-row>
  </template>
  
  <script>
    export default {
      name: 'TopNav',
      props: {
        title: {
            type: String,
            required: true,
            default: ""
        },
        },
      data() {
        return {
          info: '',
          time: null,
          currentTime: '',
          sysTime: '',
        }
      },
      computed: {},
      mounted() {
        this.init()
      },
      methods: {
        init() {
          this.time = setInterval(() => {
            this.currentTime =
              '北京时间：' + this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
            this.sysTime =
              '系统时间：' +
              this.$dayjs().add(8, 'hour').format('YYYY-MM-DD HH:mm:ss')
          }, 1000)
        },
      },
      destroyed() {
        clearInterval(this.time)
      },
    }
  </script>
  
  <style lang="scss" scoped>
    .topNavClass {
      /* color: #43ffc4; */
      color: #fff;
      height: 10%;
  
      .el-col {
        height: 100%;
      }
  
      .headLineClass {
        width: 25%;
        height: 50%;
        position: absolute;
        /* padding-top: 34px; */
        top: 10%;
      }
  
      .bottomClass {
        width: 20%;
        height: 20%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
  
      .animationClass {
        width: 25%;
        height: 20%;
        position: absolute;
        /* padding-top: 34px; */
        top: 0;
      }
  
      .title {
        /* text-align: center; */
        position: absolute;
        left: 50%;
        color: #1661c2;
        top: 40%;
        transform: translate(-50%, -50%);
      }
  
      .flexClass {
        display: flex;
        color: #00d9ff;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
      }
    }
  </style>