<template>
  <div>
    <div id="bottomRightChart" class="bottom2Chart"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixin";
import { frequency } from "../../../axios/api/LargeScreen";
export default {
  data() {
    return {
      chart: null,
      drawTiming: null,
      cdata: {
        year: null,
        weekCategory: [],
        radarData: [],
        radarDataAvg: [],
        maxData: 200,
        /*   weekMaxData: [],*/
        weekLineData: [],
      },
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)",
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)",
            },
          ],
        },
      },
    };
  },
  mixins: [echartMixins],
  mounted() {
    //this.drawTimingFn();
    this.draw();
  },
  methods: {
    async draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("bottomRightChart")
      );
      //  ----------------------------------------------------------------
      await frequency().then((res) => {
        // 清空周分类数组
        //  this.cdata.weekCategory = [];
        // 将后端数据按照前端期望的格式处理
        res.data.reverse().forEach((item) => {
          this.cdata.weekCategory.unshift(item.year); // 将年份添加到周分类数组
          this.cdata.weekLineData.unshift(item.value); // 将对应值添加到最大数据数组
        });
      });
      this.chart.setOption({
        title: {
          text: "",
          textStyle: {
            color: "#D3D6DD",
            fontSize: 24,
            fontWeight: "normal",
          },

          subtextStyle: {
            color: "#fff",
            fontSize: 16,
          },
          top: 50,
          left: 80,
        },
        legend: {
          top: 100,
          left: 80,
          orient: "vertical",
          itemGap: 15,
          itemWidth: 12,
          itemHeight: 12,
          data: ["平均", "每月"],
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        /*   radar: {
          center: ["68%", "27%"],
          radius: "30%",
          name: {
            color: "#fff",
          },
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.colorList.linearYtoG,
              opacity: 0.6,
            },
          },
          splitArea: {
            areaStyle: {
              color: "#fff",
              opacity: 0.1,
              shadowBlur: 25,
              shadowColor: "#000",
              shadowOffsetX: 0,
              shadowOffsetY: 5,
            },
          },
          indicator: [
            {
              name: "人员伤亡",
              max: this.cdata.maxData,
            },
            {
              name: "环境破坏",
              max: 10,
            },
            {
              name: "经济损失",
              max: 10,
            },
            {
              name: "社会影响",
              max: -5,
            },
          ],
        }, */
        grid: {
          left: 90,
          right: 80,
          bottom: 40,
          top: "60%",
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12,
          },
          data: this.cdata.weekCategory,
        },
        // 下方Y轴
        yAxis: {
          name: "次数",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14,
          },

          max: this.cdata.maxData,
          splitNumber: 5,
          // interval: 50,

          axisLine: {
            lineStyle: {
              opacity: 0,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.9,
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12,
          },
        },
        series: [
          /*     {
            name: "",
            type: "radar",
            symbolSize: 0,
            data: [
              {
                value: this.cdata.radarDataAvg[6],
                name: "平均",
                itemStyle: {
                  normal: {
                    color: "#f8d351",
                  },
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                areaStyle: {
                  normal: {
                    color: "#f8d351",
                    shadowBlur: 25,
                    shadowColor: "rgba(248,211,81,.3)",
                    shadowOffsetX: 0,
                    shadowOffsetY: -10,
                    opacity: 1,
                  },
                },
              },
              {
                value: this.cdata.radarData[6],
                name: "每年",
                itemStyle: {
                  normal: {
                    color: "#43dfa2",
                  },
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                areaStyle: {
                  normal: {
                    color: this.colorList.linearGtoB,
                    shadowBlur: 15,
                    shadowColor: "rgba(0,0,0,.2)",
                    shadowOffsetX: 0,
                    shadowOffsetY: 5,
                    opacity: 8,
                  },
                },
              },
            ],
          }, */
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff",
              },
            },
            lineStyle: {
              normal: {
                color: this.colorList.linearBtoG,
                width: 3,
              },
            },
            areaStyle: {
              normal: {
                color: this.colorList.areaBtoG,
              },
            },
            data: this.cdata.weekLineData,
            lineSmooth: true,
            markLine: {
              silent: true,
              data: [
                {
                  type: "average",
                  name: "平均值",
                },
              ],
              precision: 0,
              label: {
                normal: {
                  formatter: "平均值: \n {c}",
                },
              },
              lineStyle: {
                normal: {
                  color: "rgba(248,211,81,.7)",
                },
              },
            },
            tooltip: {
              position: "top",
              formatter: "{c} m",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6,
            },
          },
          {
            name: "占位背景",
            type: "bar",
            itemStyle: {
              normal: {
                show: true,
                color: "#000",
                opacity: 0,
              },
            },
            silent: true,
            barWidth: "50%",
            data: this.cdata.weekMaxData,
            animation: false,
          },
        ],
      });
    },

    destroyed() {
      clearInterval(this.drawTiming);
      window.onresize = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom2Chart {
  width: 100%;
  height: 360px;
}
</style>
