<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex">
        <span class="fs-xl text mx-2">地震必需物资排行榜</span>
      </div>
      <div class="d-flex jc-center body-box">
        <el-table :data="config.data"  style="width: 100%" :row-class-name="tableRowClassName">
          <el-table-column prop="item" label="物资"></el-table-column>
          <el-table-column prop="value" label="数量" :formatter="formatValue" style="background-color: blue"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { wuzi } from "../../axios/api/LargeScreen";

export default {
  data() {
    return {
      config: {
        data: []
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const response = await wuzi(10092);
      this.config.data = response.data;
      this.config.data.values=this.config.data.values;
    },
    formatValue(row, column, cellValue) {
      return cellValue.toFixed(3); // 保留小数点后三位
    }
  }
};
</script>

<style lang="scss" scoped>
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  .bg-color-black {
    height: 100%;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    padding-top: 10px;
    width: 100%;
    height: calc(100% - 30px);
  }
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent;
  }

  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent;
    color: white;
  }


}
</style>
<!--<template>-->
<!--  <el-table-->
<!--      :data="tableData"-->
<!--      style="width: 100%"-->
<!--      :row-class-name="tableRowClassName">-->
<!--    <el-table-column-->
<!--        prop="item"-->
<!--        label="日期"-->
<!--        >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        prop="values"-->
<!--        label="姓名"-->
<!--        >-->
<!--    </el-table-column>-->
<!--  </el-table>-->
<!--</template>-->

<!--<style>-->
<!--.el-table .warning-row {-->
<!--  background: transparent;-->
<!--}-->

<!--.el-table .success-row {-->
<!--  background: #f0f9eb;-->
<!--}-->
<!--</style>-->

<!--<script>-->
<!--import {wuzi} from "@/axios/api/LargeScreen";-->

<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      tableData: [],-->
<!--    }-->
<!--  },-->

<!--  mounted() {-->
<!--    this.fetchData();-->
<!--  },-->
<!--  methods:-->
<!--  {-->
<!--    tableRowClassName({row, rowIndex}) {-->
<!--      if (rowIndex%2 === 0) {-->
<!--        return 'warning-row';-->
<!--      } else if (rowIndex === 1) {-->
<!--        return 'success-row';-->
<!--      }-->
<!--      return '';-->
<!--    },-->
<!--    async fetchData() {-->
<!--      const response = await wuzi(10092);-->
<!--      this.tableData = response.data;-->
<!--      this.tableData.values = this.tableData.values.tofix(3);-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->
